.navbar-fixed {

  z-index: 9999;
  position: fixed !important;
  top:0;
  width: 100vw;
  background: none;
  box-shadow: none;

  nav {

    background: none;
    position: fixed;
    top:0;
    max-width: 100vw;
    box-shadow: none;
    height: auto;

    .nav-wrapper {
      max-width: 100vw;
      display: flex;
      justify-content: center;

      a {
        background: $dark-blue;
        padding: 0 20px;
        border-radius: 0 0 5px 5px;
        &.sidenav-trigger {
          i {
            font-size: 2.5rem;
          }
        }
      }

      .brand-logo {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        padding-left: 15px;
        font-size: 1.1rem;
        white-space: nowrap;
      }

      ul {
        display: flex;
        gap:7px;

        &.center {

          li {
            background: $dark-blue;
            border-radius: 0 0 5px 5px;
            position:relative;

            &.has-submenu {
              &:hover {
                cursor: default;
              }
              a {
                &:hover {
                  cursor: default;
                }
              }
            }

            a {
              display: flex;
              align-items: center;
              gap: 7px;
              height: 50px;
              i {
                display: flex;
                height: 30px;
                width: 30px;
                background: black;
                border-radius: 50%;
                justify-content: center;
                align-items: center;
                font-size: 1rem;
              }
            }

            ul {
              display: none;
              position: absolute;
              top: 100%;
              left: 0;
              min-width: 100%;
              width: max-content;

              li {
                width: 100%;
                height: 40px;
                padding: 0 0;
                line-height: 40px;
                text-align: left;
                border-radius: 0;
                background: $dark-blue;

                &:hover {
                  cursor: pointer !important;
                }
                a {
                  &:hover {
                    cursor: pointer !important;
                  }
                }

                &:first-of-type {
                  border-radius: 0 12px 0 0;
                }
                &:last-of-type {
                  border-radius: 0 0 12px 0;
                }
                &:hover {
                  background: #000;
                }
                a {
                  text-align: left;
                  line-height: 40px;
                  height: 40px;
                  border-radius: 0;
                  background: none;
                }
              }
            }
            &:hover {
              border-radius: 0;
              ul {
                display: flex;
                flex-direction: column;
                gap:0;
              }
            }
          }
        }

        li {

          &.active {
            background-color: $dark-blue;
          }

          &:hover {

            a {
              color:white;
            }

          }

          a {
            font-size: 0.95rem;
            height: 64px;
            transition: 0.35s;
            &:hover {
              background-color: rgba(0, 0, 0, 0.75);
            }
            i {
              font-size: 0.9rem;
            }
          }

        }
      }
    }
  }
}

.sidenav-overlay {

  z-index: 999 !important;

}