.flex {
  display:flex;

  &.flex-m-column {
    @media screen and (max-width: 992px) {
      flex-direction: column;
    }
  }

  .flex-1 {
    flex: 1;
    @media screen and (max-width: 992px) {
      flex: auto;
    }
  }

  .col {
    margin:0 !important;
  }
  &.flex-row {
    flex-direction: row;
  }
  &.flex-column {
    flex-direction: column;
  }
  &.flex-justify-center {
    justify-content: center;
  }
  &.flex-justify-start {
    justify-content: flex-start;
  }

  &.flex-justify-between {
    justify-content: space-between;
  }

  &.flex-justify-around {
    justify-content: space-around;
  }

  &.flex-justify-end {
    justify-content: flex-end;
  }

  &.flex-content-center {
    align-content: center;
  }
  &.flex-content-start {
    align-content: flex-start;
  }
  &.flex-content-end {
    align-content: flex-end;
  }

  &.flex-items-center {
    align-items: center;
  }
  &.flex-items-start {
    align-items: flex-start;
  }
  &.flex-items-end {
    align-items: flex-end;
  }
  &.wrap {
    flex-wrap: wrap;
  }
  &.no-wrap {
    flex-wrap: nowrap;
  }
  &.flex-grow {
    flex-grow: 1;
  }
  &.stretch {
    align-items: stretch;
  }
  &.self-stretch {
    align-self: stretch;
  }
}
