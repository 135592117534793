.card {
  width: 100%;
  &.walk {
    .card-image {
      .walk-logo {
        width: 80px;
        height: 80px;
        position: absolute;
        top: 10px;
        left: 10px;
        background-size: cover !important;
        border-radius: 50%;

      }
    }
    .card-content {
      a {
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .card-action {
      gap: 10px;
      a {
        color: white;
        transition: 0.35s;
        &:hover {
          color: $blue-moulin;
        }
      }
    }
  }
  .card-action {
    background: none;
    border-top: 1px solid rgba(160, 160, 160, 0.2);
    position: relative;
    padding: 15px;
  }
}