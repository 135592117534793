// FONTS
// Imported in base.html.twig from google fonts
$main-font: 'Montserrat', sans-serif;
$serif-font: 'DM Serif Display', serif;
$merriweather: "Merriweather", serif;

// COLORS
$blue-moulin: rgba(51,180,216);
$light-grey: #FEFEFE;
$light-blue-grey: #eceff1;
$dark-grey: #424242;
$moulin-orange: #ff6f00;
$dark-blue: #153448;

// STYLES

$radius : 8px;

.orange-deep {
  background: orangered !important;
}

.orange-moulin {
  background: $moulin-orange !important;
}

.orange-moulin-text {
  color: $moulin-orange !important;
}

.dark-blue {
  background: $dark-blue !important;
}

.blue-moulin {
  background: $blue-moulin !important;
}

.dark-blue-text {
  color: $dark-blue !important;
}