@for $i from 0 through 20 {
  .rounded-#{$i} {
    border-radius : #{$i}px;
  }

  .rounded-top-#{$i} {
    border-radius:  #{$i}px #{$i}px 0 0;
  }

  .rounded-bottom-#{$i} {
    border-radius:  0 0 #{$i}px #{$i}px;
  }

  .rounded-left-#{$i} {
    border-radius:  #{$i}px 0 0 #{$i}px;
  }

  .rounded-right-#{$i} {
    border-radius:  0 #{$i}px #{$i}px 0 ;
  }

}