.agenda {
  width: 100%;
  &:not(.cal-dispos) {
    min-height: 500px;
    height: max-content;
  }

  .fc-header-toolbar {
    h2 {
      font-family: $main-font;
    }
  }
}

#cal-dispos {
  .agenda {

    .fc-header-toolbar {
      .fc-toolbar-chunk {
        .fc-toolbar-title {
          background: #00acc1;
          font-size: 1.5rem;
          font-weight: bold;
          color: white;
          padding: 5px 10px;
          border-radius: 4px;
        }
      }
    }
    .fc-scrollgrid {
      border:none;
    }
    td, th {
      border:none !important;
    }
    .fc-col-header-cell {
      .fc-scrollgrid-sync-inner {

        a {
          color: dimgrey;
        }
      }
    }

    .fc-day {
      &:has(.fc-event-title-container) {
        .fc-daygrid-day-top {
          .fc-daygrid-day-number {
            background: $blue-moulin;
          }
        }
      }
      .fc-daygrid-day-top {
        .fc-daygrid-day-number {
          height: 20px;
          width: 20px;
          font-size: 0.7rem;
          background: var(--fc-button-bg-color);
          color:white;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top:3px;
        }
      }
    }
  }
}