.events-list {
  h4 {
    font-family: $main-font;
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 35px;
    @media screen and (max-width: $small-screen) {
      text-align: center !important;
    }
  }
  ul {
    margin-left:20px;

    li {
      display: flex;
      justify-content: start;
      align-items: center;
      gap: 12px;
      position: relative;
      margin:35px 0 20px 0;
      span {
        &.title {
          font-family: $main-font;
          font-weight: bold;
          font-size: 1rem;
        }
        &.time {
          position: absolute;
          top:0;
          left: 0;
          margin-top:-20px;
          font-family: $main-font;
          font-size: 0.9rem;
          background: #F3F3F3;
          padding: 0 10px;
          border-radius: 200px;
          margin-left: -20px;
        }
      }
    }
  }
}