.m-toast {
  font-family: $main-font;
  font-weight: bold;
  &.error {
    background: #b9151b;
  }
  &.success {
    background: seagreen;
  }
  &.warning {
    background: orangered;
  }
  &.info {
    background: $blue-moulin;
  }
}