.information {
    padding: 15px;
    background: rgba(0,0,0,0.7);
    color: white;
    border-radius: 8px;
    a {
        color: white;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
            color: orange;
        }
    }
}