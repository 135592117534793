.the-events-date {
  justify-content: start;
  @media screen and (max-width: $small-screen) {
    justify-content: center;
  }

  .inter-dates {
    margin-right: 15px;
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    @media screen and (max-width: $small-screen) {
      margin-left:15px;
    }
  }
}

.event-date {
  background: white;
  box-shadow: 7px 7px 10px rgba(0,0,0,0.3);
  border-radius:12px;
  position: relative;
  min-width: 150px;
  margin-right: 20px;
  transform: rotate(-5deg);
  height: max-content;
  @media screen and (max-width: $small-screen) {
    min-width: 120px;
  }
  &.end-date {
    transform: rotate(5deg);
    @media screen and (max-width: $small-screen) {
      transform: rotate(0deg);
    }
  }
  @media screen and (max-width: $small-screen) {
    transform: none;
    margin-right: 0;
  }
  .month {
    background: darkred;
    color:white;
    text-transform: uppercase;
    text-align: center;
    border-radius:12px 12px 0 0;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: sans-serif;
    font-weight: bold;
    line-height: 40px;
  }
  .day {
    width: 100%;
    font-family: sans-serif;
    font-weight: bold;
    font-size: 4.5rem;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .year {
    text-align: center;
    width: 100%;
    font-family: sans-serif;
    font-weight: bold;
    margin-top:-10px;
  }
}