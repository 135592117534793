.page-complements {
  font-family: $main-font;
  ul {
    padding-left:15px;
    li {
      list-style: disc;
    }
  }

  .news {
    position: relative;
    padding: 12px;
    border-radius: 8px;
    background: #00acc1;
    margin-top: 30px;
    &:before {
      position: absolute;
      top:0;
      left: 0;
      display: block;
      content: 'Nouveau';
      font-weight: bold;
      background: #0a4148;
      color: white;
      padding: 5px 8px;
      border-radius: 5px;
      transform: translateY(-50%) translateX(-10%) rotate(-5deg);
    }
    .price {
      display: block;
      font-weight: bold;
    }
  }

}

.wp-block-gallery {
  display: flex;
  height: 300px;
  justify-content: space-between;
  margin:0;
  gap: 10px;
  img {
    height: 100%;
    width: auto;
    max-width: 30%;
    flex-wrap: wrap;
  }
}

.wp-block-column {
  &.column {
    &.pic {
      display:flex;
      flex-direction: column;
      justify-content: center;
      align-content: start;
      align-items: start;
      &.right {
        align-content: end;
        align-items: end;
      }
      figure {
        margin: 0;
      }
    }
  }
}