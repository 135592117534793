.page-footer {
  position: relative;
  padding:80px 0 150px 0;
  max-width: 100vw;
  background: $dark-blue;
  background-size: 10%;
  &:after {
    position: absolute;
    top: 0;
    left:0;
    width: 100%;
    height:45px;
    background-size: 45px auto;
    background: url('./../../images/vague_bleue.png') repeat-x;
    content: ' ';
  }

  ul {
    li {
      font-family: $main-font;
      list-style: disc;
    }
  }
}