.sidewin {
  position: fixed;
  top: 0;
  margin:0;
  height: 100vh;
  width: 300px;
  transition: all 0.3s ease-in-out;
  z-index: 99999;
  background: white;
  padding:0 20px;
  overflow-y: scroll;
  .side-closer {
    margin:10px 0 0 50px;
    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      font-size: 1rem;
    }
  }

  .head {
    padding:15px 50px;
    @media screen and (max-width: $small-screen) {
      padding:15px 30px;
    }
    h3 {
      margin: 0;
      font-family: $main-font;
      font-size:1.8rem;
      font-weight: 700;
    }
    p {
      padding:0;
      width: 100%;
      max-width: 100%;
      background: none;
      color: #424242;
    }
  }
  .content {
    padding:50px;
    @media screen and (max-width: $small-screen) {
      padding:50px 30px;
    }

    iframe {

    }

    #agenda {

        max-height: 70% !important;
        width: calc(100% - 100px);


      @media screen and (max-width: $small-screen) {
        width: 100%;
      }

      .agenda {

        .fc-scrollgrid {
          border:none;
        }
        td, th {
          border:none !important;
        }
        .fc-col-header-cell {
          .fc-scrollgrid-sync-inner {

            a {
              color: dimgrey;
            }

          }
        }

        .fc-day {
          &:has(.fc-event-title-container) {
            .fc-daygrid-day-top {
              .fc-daygrid-day-number {
                background: $blue-moulin;
              }
            }
          }
          .fc-daygrid-day-top {
            .fc-daygrid-day-number {
              height: 20px;
              width: 20px;
              font-size: 0.7rem;
              background: var(--fc-button-bg-color);
              color:white;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-top:3px;

            }
          }
        }

      }
    }
  }

  &.side-right {
    right: 0 !important;
    transform: translateX(125%);
    box-shadow: -20px 0 30px rgba(0,0,0,0.7);
  }
  &.side-left {
    left: 0 !important;
    transform: translateX(-125%);
  }

  &.open {
    transform: translateX(0);
  }

  &.large {
    width: 60vw;
    @media screen and (max-width: $small-screen) {
      width: 100vw;
    }
    &.open {
      box-shadow: -40vw 0 0px rgba(0,0,0,0.7);
      @media screen and (max-width: $small-screen) {
        box-shadow: none;
      }
    }
  }

}