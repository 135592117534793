$small-screen-up: 601px !default;
$medium-screen-up: 1501px !default;
$large-screen-up: 1601px !default;
$small-screen: 600px !default;
$medium-screen: 1500px !default;
$large-screen: 1600px !default;

/* assets/css/app.css */
@import "notyf/notyf.min.css";
@import "tippy.js/dist/tippy.css";
@import 'tippy.js/themes/light.css';
@import 'tippy.js/themes/light-border.css';
@import 'tippy.js/animations/scale.css';

@import "commons/variables";
@import "commons/information";
@import "commons/notyf";
@import "commons/switchers";
@import "~materialize-css";
@import "commons/navbar";
@import "commons/globals";
@import "helpers/helpers";
@import "commons/flex";
@import "commons/sidewin";
@import "commons/cards";
@import "home/home";
@import "home/postit";
@import "home/next_event";
@import "agenda/agenda";
@import "calendar/calendar";
@import "events/activity";
@import "events/partner";
@import "events/date";
@import "events/event_list";
@import "events/form";
@import "pages/infos_pratiques";
@import "pages/animations";
@import "commons/map";
@import "commons/footer";
@import "pages/pages";

