

.card {
  &.activity {
    box-shadow: 8px 8px 12px rgba(0,0,0, 0.4);
    opacity:0;
    transform: translateX(50%);
    transition: all 0.4s ease-in-out;
    border-radius: 12px 0 12px 0;
    margin-bottom: 60px;

    &.event {
      border-radius: 12px;
      .card-image {
        border-radius: 12px 12px 0 0 ;
        .event-date {
          width: 150px;
          transform: scale(75%);
          position: absolute;
          top:0;
          right: 0;
          transform-origin: top right;
          border-radius: 0 12px 0 12px;
          box-shadow: none;
          margin:0;

          .month {
            border-radius: 0 12px 0  0;
          }

        }
      }
      .card-action {
        //border-radius: 0 0 12px 12px;
          &.program-action {
          a {
              &:hover {
                color: orangered !important;
                opacity: 1 !important;
              }
            }
        }
        .full {
          padding:15px 0;
        }
      }
    }

    &.visible {
      transform: translateX(0);
      opacity: 1;
    }
    .head {
      position:absolute;
      top:0;
      left:0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      z-index: 9999;
      font-family: $main-font;
      transform: translateY(-100%) ;
      .activity-type {
        padding:0 15px;
        border-radius:5px 5px 0 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 25px;
      }
    }

    .card-image {
      height: 250px;
      overflow: hidden;
      background-size: cover !important;
      border-radius: 12px 0 0 0;
    }
    .card-title {
      padding: 13px 20px 13px 20px;
      line-height: 1.7rem;
      font-family: $main-font;
      background: rgba(0,0,0, 0.7);
      width: 100%;
      display: flex;
      flex-direction: column;
      .times {
        font-size:1rem;
        font-weight: 400;
        color: white;
        top:0;
        left:12px;
        transform: translateY(-0%);
        border-radius: 2px;
      }
      .activity-title {
        font-weight: bold;
      }
    }
    .card-content {
      padding: 20px;

    }
    .card-action {
      padding: 15px 20px;
      display: flex;
      justify-content: center;
      bottom: 0;
      border-radius: 0 0 12px 0;

      .prices {
        position: absolute;
        top:0;
        left:50%;
        transform: translateY(-50%) translateX(-50%);
        font-family: $main-font;
        background: #f3f3f3;
        width: max-content;
        font-size: 0.9rem;
        padding:0 12px;
        border-radius: 200px;
      }
      .resa {
        .full {
          padding:15px 0;
        }
        .link {
          text-transform: uppercase;
          color: $moulin-orange;
          font-family: $main-font;
          font-weight: bold;
          display: block;
          text-align: center;
          &:hover {
            cursor: pointer;
          }
        }
        .alert {
          display: block;
          font-family: $main-font;
          font-weight: 700;
          margin-top:7px;
          text-align: center;
        }
      }
    }
  }
}