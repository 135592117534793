@import url('https://fonts.googleapis.com/css2?family=Kalam&display=swap');

.post-it-block {
  position: absolute;
  top: 70px;
  left: 50%;
  width: 20vw;
  height: 20vw;
  touch-action: none;
  user-select: none;
  border-radius: 4px;
  z-index: 999999;

  .post-it-shadow {
    position: absolute;
    content: ' ';
    display: inline-block;
    height: 0;
    width: 0;
    top: 15px;
    right: -5px;
    border-bottom: 18vw solid rgba(0,0,0,0.7);
    border-left: 18vw solid transparent;
    filter: blur(5px);
    z-index:-1;
  }

  &.left {
    transform: rotate(-7deg) scale(1) translate(0px, 0px);
    left: 40%;

  }

  &.right {
    transform: rotate(7deg) scale(1) translate(100px, 0px);
  }
  .post-it {
    width: 100%;
    height: 100%;
    background: url('./../../images/postit-left.svg') no-repeat center center;
    background-size: contain;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 30px;
    z-index: 2;

    &:before {
      position: absolute;
      content: ' ';
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: #424242;
      top: 30px;
      left: 50%;
      box-shadow: 1px 1px 2px rgba(0,0,0,0.7);
    }

    .post-it-content {
      margin-top: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 30px;
      height: 100%;
      transform: rotate(-3deg);

      .post-it-title {
        font-size: 1.5rem;
        font-weight: 700;
        text-align: center;
        font-family: 'Kalam', cursive;

      }
    }
  }
}