.infos-pratiques {
  .info-title {
    font-family: $main-font;
    font-size: 1.3rem;
    font-weight: bold;
    color: #424242;
  }

  ul {
    li {
      font-family: $main-font;
      list-style: disc;
    }
  }
}

.prestation {
  width: 40px;
  height: 40px;
  background: $dark-blue;
  color:white;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 1.3rem;
  }
  img {
    width: 80%;
  }
}