.left-side {
  position: fixed;
  top:0;
  left:-50vh;
  background: $blue-moulin;
  width: 100vh;
  height: 100vh;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  padding-top:150px;
  align-items: start;
  flex-direction: column;
  padding-left: calc(50vh + 20px);

  .illustration {
    position:absolute;
    width: 25vh;
    height: 25vh;
    background: black;
    display: block;
    border-radius: 50%;
    content: ' ';
    top: 5%;
    right: 3%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  h1 {
    font-family: $main-font;
    font-size: 2rem;
    margin: 0;
    text-shadow: none;
    border: none;
    &:before {
      display: none;
    }
  }
}

.main {
  margin-left: 60vh;
  padding-left: 20px;
  width: calc(100vw - 50vh - 25vh);

  .bubles {
    display:flex;
    flex-wrap: wrap;
    grid-gap: 50px 50px;
    .block-buble {
      width: calc((100vw - 50vh - 25vh) / 3);
      height: calc((100vw - 50vh - 25vh) / 3);
      border-radius: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      padding:20px;
      position: relative;
      .illustration {

      }
      h2 {
        color:white;
        font-family: $main-font;
        font-size: 2rem;
        font-weight: 900;
        margin: 0;
        padding: 0;
      }
      a {
        &.button {
          padding:8px 20px;
          border-radius: 200px;
          background: white;
          font-family: $main-font;
          color: #424242;
          font-size: 1rem;
          font-weight: 700;
          display: flex;
          justify-content: center;
          align-items: center;
          gap:6px;
          margin-top:15px;
        }
      }
    }
  }

}

.right-side {
  position:fixed;
  display: flex;
  top:0;
  right:0;
  height: 50vh;
  width: 50vh;
  margin-right: -25vh;
  border-radius: 50%;
  background: orange;
}

#main-nav {
  background: $blue-moulin;
  width: 50vh;
}