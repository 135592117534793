.page-footer {
  position: relative;
  padding:80px 0 150px 0;
  max-width: 100vw;
  background: $dark-blue;
  background-size: 10%;
  &:after {
    position: absolute;
    bottom:100%;
    left:50px;
    content: ' ';
    width: 80px;
    height: 80px;
    background: url('./../../images/martin.svg') no-repeat bottom left;
    background-size: contain !important;
    display: block;
    z-index: 999;
  }

  ul {
    li {
      font-family: $main-font;
      list-style: disc;
    }
  }
}