
@for $i from 0 through 150 {

    .ml-#{$i} {
        margin-left: #{$i}px !important;
    }
    .pl-#{$i} {
        padding-left: #{$i}px !important;
    }

    .pl-sm-#{$i} {
        @media screen and (max-width: 992px) {
            padding-left: #{$i}px !important;
        }
    }

    .pl-md-#{$i} {
        @media screen and (min-width: 993px) {
            padding-left: #{$i}px !important;
        }
    }

    .mr-#{$i} {
        margin-right: #{$i}px !important;
    }
    .pr-#{$i} {
        padding-right: #{$i}px !important;
    }

    .mt-#{$i} {
        margin-top: #{$i}px !important;
    }
    .pt-#{$i} {
        padding-top: #{$i}px !important;
    }

    .mb-#{$i} {
        margin-bottom: #{$i}px !important;
    }
    .pb-#{$i} {
        padding-bottom: #{$i}px !important;
    }

    .ms-#{$i} {
        margin-left: #{$i}px !important;
        margin-right: #{$i}px !important;
    }
    .ps-#{$i} {
        padding-left: #{$i}px !important;
        padding-right: #{$i}px !important;
    }

    .mud-#{$i} {
        margin-top: #{$i}px !important;
        margin-bottom: #{$i}px !important;
    }
    .pud-#{$i} {
        padding-top: #{$i}px !important;
        padding-bottom: #{$i}px !important;
    }

    .m-#{$i} {
        margin: #{$i}px !important;
    }
    .p-#{$i} {
        @media screen and (min-width: $small-screen-up) {
            padding: #{$i}px !important;
        }
    }

    .p-m-#{$i} {
        @media screen and (max-width: 992px) {
            padding: #{$i}px !important;
        }
    }

    .pr-m-#{$i} {
        @media screen and (min-width: $small-screen-up) {
            padding-right: #{$i}px !important;
        }
    }

    .p-s-#{$i} {
        @media screen and (max-width: $small-screen) {
            padding: #{$i}px !important;
        }
    }

    .gap-#{$i} {
        gap: #{$i}px;
    }

}