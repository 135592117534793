.partner {
  display: flex;
  background: rgba(0,0,0,0.6);
  border-radius:8px;
  align-items: center;
  gap:15px;
  padding: 10px;
  margin: 12px 0 12px 0;
  position: relative;

  .partner-title {
    display: block;
    width: max-content;
    font-family: $main-font;
    font-weight: bold;
    font-size: 0.9rem;
    background: black;
    padding:4px 7px;
    border-radius:4px;
  }
  .partner_logo {
    max-width: 30%;
    img {
      max-height: 64px;
    }
  }
  .partner_name {
    font-family: $main-font;
    color: white;
    a {
      text-decoration: underline;
      color: white;
      opacity:0.8;
      &:hover {
        text-decoration: none;
      }
    }
  }
}