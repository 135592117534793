.card-reveal {
  .activity-name.card-title {
    font-family: $main-font;
    font-weight: bold;
    background: none;
    font-size: 1rem;
  }

  .complements {
    ul {
      margin-left: 12px;
      li {
        list-style: disc;
        font-family: $main-font;
        margin-bottom: 10px;
      }
    }
  }

}

@mixin inputs {
  border:1px solid #ccc;
  border-radius: 5px;
  padding-left: 10px;
  &:not([readonly]) {
    &:focus {
      border:1px solid #424242;
      box-shadow: none;
    }
  }
}

.reservation-type, .contact-type {
  form {
    .error {
      font-size: 0.8rem;
      color: #b9151b;
      font-family: "Montserrat", sans-serif;
      line-height: 0.8rem;
      margin-bottom: 12px;
      padding-left: 2px;
      margin-top: -4px;
    }
    input {
      &[type="text"] {
        @include inputs;
      }
      &[type="email"] {
        @include inputs;
      }
      &[type="number"] {
        @include inputs;
      }
    }
  }
}

label {
  font-family: $main-font;
}
.field {
  &.message {
    display: none;
  }
}