.events-list {
  display: flex;
  flex-direction: column;
  gap: 15px;

  h2 {
    font-family: $main-font;
    font-size: 1.6rem;
    font-weight: bold;
    border-radius: 8px;
    padding:10px 10px;
    color:$dark-blue;
    &.disabled {
      opacity: 0.6;
    }
  }

  .event-list-element {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 20px;
    @media screen and (max-width: 992px) {
      &.expo {
        flex-wrap: wrap;
      }
      &:not(.expo) {
        padding-left:20px;
      }
    }
    &.disabled {
      opacity: 0.6;
    }
    .events-list-date {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 1.1rem;
      font-family: $main-font;
      min-width: 35px !important;
      box-shadow: 5px 5px 6px rgba(0,0,0,0.3);
      width:35px;
      height: 35px;
      background: $blue-moulin;
      color:white;
      border-radius: 50%;
    }

    .events-expo-list-date {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      font-size: 1.1rem;
      font-family: $main-font;
      box-shadow: 5px 5px 6px rgba(0,0,0,0.3);
      background: $blue-moulin;
      color:white;
      border-radius: 200px;
      white-space: nowrap;
      padding: 1px 15px;
    }

    h3 {
      font-size: 1.3rem;
      text-decoration: none;
      font-weight: normal;
      margin:0;
      padding:0;
    }
    .types {
      font-style: italic;
    }
  }
}