#reservation-form-div {
  position: fixed;
  top: 0;
  right: 0;
  width: 50vw;
  height: 100vh;
  background: white;
  z-index: 9999999;
  border: 1px solid #CCC;
  padding: 30px;
  box-shadow: -35px -5px 35px rgba(0,0,0,0.6);
  transform: translateX(105%);
  transition: 0.25s;
  overflow-y: scroll;
  @media screen and (max-width: 992px) {
    width: 100vw;
  }
  &.visible {
    transform: translateX(0);
  }

  .closer {
    position: absolute;
    right: 40px;
    top: 10px;
  }
}


#cal-dispos {
  position: relative;

  a.fc-event {
    display: flex;
    justify-content: center;

    .fc-event-title {
      display: none;
    }
    .fc-event-time {
      font-size: 16px;
      color: $dark-blue;
      font-weight: bold;
    }

    &:hover, &:active, &:focus {
      cursor: pointer;
      background: #9ed9ec;
      border-radius: 200px;
    }
  }
}